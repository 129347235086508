import React, { useState, useEffect } from "react";
import $ from "jquery";
// import { DemoContestQuery } from "../../prismic/staticQueries/DemoContestAllQuery/index.js"
import "react-multi-carousel/lib/styles.css";
import { connect, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import StocksIcons from "./Components/StocksIcons";
import ReactPaginate from "react-paginate";

const DemoContest = () => {
  const language = useSelector((state) => state.language);
  //const DemoContestData = DemoContestQuery(language);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [objJson, setObjJson] = useState([]);
  const [current_page, setCurrentPage] = useState(0);
  const [filteredData, setFilteredData] = useState([])
  const [searchFilter, setSearchFilter] = useState("")
  const [initialDataLength, setInitialDataLength] = useState(0)
  const records_per_page = 10;

  useEffect(() => {
    fetch('https://metatrader.roxcapitals.com/lead.php')
      .then(response => response.json())
      .then(data => {
        // setObjJson(data);
        // setFilteredData(data);
        let originalData = JSON.stringify(data)
        let paginated = data
        paginated = paginated.splice(current_page*records_per_page, records_per_page)
        console.log("paginated",paginated)
        setObjJson(JSON.parse(originalData))
        setFilteredData(paginated)
        // setCurrentPage(0)
        setInitialDataLength(data?.length) // Initialize filteredData with all data
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const filterSearch = (e) => {
    const name = e.target.value.trim().toLowerCase();
    const filtered = objJson.filter(item => item.name.toLowerCase().includes(name));
    console.log("name.length",name.length)
    if(name.length == 0){
      setCurrentPage(0)
    }
    setFilteredData(filtered);
    setSearchFilter(name)
    
  };

  useEffect(() => {
    if(objJson?.length > 0){
      let originalData = JSON.stringify(objJson)
      let paginated = objJson
      paginated = paginated.splice(current_page*records_per_page, records_per_page)
      console.log("OBJJSON",objJson);
      console.log("paginated",paginated)
      setObjJson(JSON.parse(originalData))
      setFilteredData(paginated)
    }
    
  },[current_page])
  
  const handlePageClick = (event) => {
    console.log("event",event.selected)
    setCurrentPage(event.selected);
  };


  // const prevPage = () => {
  //   let findall_contentDiv = $('.leaderboard__profiles').empty();

  //   if (current_page > 1) {
  //     setCurrentPage(current_page - 1);
  //     changePage(current_page - 1);
  //   }
  // };

  // const nextPage = () => {
  //   let findall_contentDiv = $('.leaderboard__profiles').empty();

  //   if (current_page < numPages()) {
  //     setCurrentPage(current_page + 1);
  //     changePage(current_page + 1);
  //   }
  // };

  // const changePage = (page) => {
  //     let findall_contentDiv = document.querySelector('.leaderboard__profiles');

  //   fetch('https://jsonplaceholder.typicode.com/users')
  //     .then(response => response.json())
  //     .then(data => {
  //       setObjJson(data);
  //       setFilteredData(data)
  //       console.log("change page", data);
  //       // rest of your code for changing pages...
  //     })
  //     .catch(error => {
  //       console.error('Error fetching data:', error);
  //       // Handle the error as needed
  //     });
  // };

  
  // const numPages = () => {
  //   return Math.ceil(objJson.length / records_per_page);
  // };

  // const handleSearch = () => {
  //   const name = document.getElementById('search').value.trim();
  //   let findall_contentDiv = document.querySelector('.leaderboard__profiles').empty();

  //   if (name !== '') {
  //     fetch(`https://jsonplaceholder.typicode.com/users`)
  //       .then(response => response.json())
  //       .then(data => {
  //         setObjJson(data)
  //         console.log("handle search", data);
  //         // Your data processing and UI updates based on the search results
  //       })
  //       .catch(error => {
  //         console.error('Error fetching data search:', error);
  //         // Handle the error as needed
  //       });
  //   } else {
  //     changePage(current_page);
  //   }
  // };

  // function filterSearch(e){
  //   const filterData = objJson?.filter((ele) =>  ele.name.toLowerCase().includes(e.target.value.toLowerCase()))
  //   console.log("filterData", filterData)
  //   setFilteredData(filterData)
    
  // }

  // const handlePageClick = (event) => {
  //   const newOffset = (event.selected * 10);
  //   const slicedData = objJson.slice(newOffset, 10)
  //   setFilteredData(slicedData)
  // };
  return (
    <>
      <div class="row-search">
        <span class="fw-bold">Contest End on 31 October 2024</span>

        <div class="form-group">
          <label for="inp" class="inp">
            <input type="text" onChange={filterSearch} id="search" name="search" placeholder="Search by Name...." />
          </label>

        </div>
      </div>
      <div class="leaderboard__profiles_head">
        <article class="leaderboard__profile_head headings">
          <span class="leaderboard_head">Rank</span>
          <span class="leaderboard_head">Name</span>
          <span class="leaderboard_head mob-hide">Profit</span>
          <span class="leaderboard_head mob-hide">Balance</span>
        </article>
      </div>
      <div className="leaderboard__profiles">
  {filteredData.map((item, index) => {
    let medalImage;
    if (index === 0) {
      medalImage = 'gold-rank.png';
    } else if (index === 1) {
      medalImage = 'silver-rank.png';
    } else if (index === 2) {
      medalImage = 'bronze-rank.png';
    }
    return (
      <article key={index} className="leaderboard__profile">
        <span className="leaderboard__rank">
          {item.new_rank}
          {item.new_rank < item.old_rank ? (
            <img src="https://roxcapitals.com/wp-content/uploads/2022/08/up-arrow.svg" width="15px" alt="Up" />
          ) : item.new_rank > item.old_rank ? (
            <img src="https://roxcapitals.com/wp-content/uploads/2022/08/down-arrow.svg" width="15px" alt="down" />
          ) : (
            <img src="https://roxcapitals.com/wp-content/uploads/2022/08/neutral-svg.svg" width="15px" alt="Neutral" />
          )}
        </span>
        <span className="leaderboard__name">{item.name}<img src={item.flag} style={{ width: "30px" }}></img></span>
        <span className="leaderboard__profit mob-hide">{item.profit}$</span>
        <span className="leaderboard__balance mob-hide">
          {item.balance}$
          {index < 3 && <img src={`https://roxcapitals.com/wp-content/uploads/2022/08/${medalImage}`} style={{ width: "30px", marginLeft: "15px" }} alt={`Position ${index + 1}`} />}
        </span>
      </article>
    );
  })}
 
</div>
<div className="demo-pagination">
   {searchFilter.length == 0 && <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={Math.ceil(initialDataLength / records_per_page)+1}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        pageClassName={"whenEmptyClickOnOne"}
      />}
      </div>
      <div id="listingTable"></div>
      {/* <div class="win-pagination">
        <a href="javascript:prevPage()" id="btn_prev" class="btn-page">Previous</a>
        <a href="javascript:nextPage()" id="btn_next" class="btn-page">Next</a>
        <span id="pageNo" style={{ visibility: 'hidden' }}></span>
      </div> */}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(DemoContest);